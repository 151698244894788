<template>
  <app-overlay>
    <db-searchable-table
      v-model="filters"
      @row-clicked="showInvoiceDetails"
      default-sort-by="issue_date"
      :items="items"
      :fields="fields"
      :total-rows="totalRows"
      searchable
      @changed="getData"
      hover
      :use-route-query="!selectedCompany && !selectedContact"
    >
      <template #modalbutton>
        <app-button v-if="$can('create', 'finance-invoices')" @click="showNewInvoiceForm" text="Yeni Fatura" icon="PlusIcon" size="md" />
      </template>
      <template #due_date="{ item }">
        <div class="d-flex flex-column no-wrap">
          {{ $FormattedDate(item.due_date) }}
          <small>
            <b-badge v-if="item.due_day" variant="light-primary">{{ item.due_day }} Gün Gecikti</b-badge>
          </small>
        </div>
      </template>
      <template #actions="{ item }">
        <app-dropdown>
          <b-dropdown-item @click="showInvoiceDetails(item)"> <feather-icon class="mr-25" icon="FileTextIcon" /> Fatura Detayı </b-dropdown-item>
          <b-dropdown-item v-if="$can('create', 'finance-invoices')" @click="showCopyForm(item)"> <feather-icon class="mr-25" icon="CopyIcon" /> Kopya Oluştur </b-dropdown-item>

          <b-dropdown-item v-if="!selectedCompany && !selectedContact" @click="showContactDetails(item)">
            <feather-icon class="mr-25" icon="UserIcon" /> Müşteri Detayı
          </b-dropdown-item>
          <b-dropdown-item @click="getEInvoice(item)"><feather-icon class="mr-25" icon="DownloadCloudIcon" /> E-Fatura (PDF) </b-dropdown-item>
          <b-dropdown-item @click="showSendInvoiceToEmailForm(item)"> <feather-icon class="mr-25" icon="MailIcon" /> E-Postaya Gönder </b-dropdown-item>
          <b-dropdown-item><feather-icon class="mr-25" icon="PlusIcon" /> Tahsilat Ekle </b-dropdown-item>
        </app-dropdown>
      </template>
    </db-searchable-table>
  </app-overlay>
</template>

<script>
import NewInvoiceForm from "./NewInvoiceForm.vue";
import InvoicePaymentForm from "./InvoicePaymentForm.vue";
import InvoiceDetails from "./InvoiceDetails/InvoiceDetails.vue";
import SendInvoiceToEmailForm from "./SendInvoiceToEmailForm.vue";
export default {
  data() {
    return {
      items: [],
      included: [],
      filters: {},
      totalRows: 0,
    };
  },
  computed: {
    fields() {
      return [
        ...(!this.selectedCompany?.parasut_contact_id && !this.selectedContact?.contact_id ? [{ key: "contact.name", label: "Firma Adı", limit: 20, tdClass: "no-wrap" }] : []),
        { key: "description", label: "Fatura Adı", limit: 20 },
        { key: "invoice_no", label: "Fatura No" },
        { key: "issue_date", label: "Düzenlenme Tarihi", sortable: true, formatDate: true },
        { key: "due_date", label: "Vade Tarihi", sortable: true, formatDate: true },
        { key: "net_total", label: "Tutar", formatToTL: true },
        { key: "remaining", label: "Bakiye", formatToTL: true },
        { key: "overdue_interest.amount", label: "Gecikme Faizi", formatToTL: true },
        { key: "actions", label: "İşlemler" },
      ];
    },
    selectedContact() {
      return this.$store.getters?.selectedParasutContact;
    },
    selectedCompany() {
      return this.$store.getters.selectedCompany;
    },
    breakPoint() {
      return this.$store.getters["app/currentBreakPoint"];
    },
  },
  methods: {
    getData() {
      this.$axios
        .get("/finance/parasut-invoices/list", {
          params: {
            ...this.filters,
            "contact.id": this.selectedCompany?.parasut_contact_id ?? this.selectedContact?.contact_id,
            payment_status: this.selectedCompany?.parasut_contact_id || this.selectedContact?.contact_id ? JSON.stringify({ $ne: "paid" }) : null,
            searchFields: JSON.stringify(["contact.name", "invoice_no", "description"]),
          },
          loading: "table",
        })
        .then((result) => {
          this.totalRows = result.data?.total ?? 0;
          this.items = result.data?.data ?? [];
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getEInvoice(item) {
      this.$axios
        .get(`/finance/parasut-invoices/get-e-invoice`, { params: { e_invoice_type: item?.e_invoice_type, e_invoice: item?.e_invoice }, loading: "table" })
        .then((result) => {
          if (result?.data?.url) window.open(result?.data?.url, "_blank");
        })
        .catch((err) => {
          this.$emitter?.$emit("Notification", { title: "Hata Oluştu", variant: "danger", message: err?.data?.message });
        });
    },
    showInvoicePaymentForm(item) {
      this.$showAppSidebar("Tahsilat Ekle", InvoicePaymentForm, {
        data: { ...item },
        callback: this.getData,
        isModelOpen: Boolean(this.item),
      });
    },
    showCopyForm(item) {
      this.$showAppModal({ title: "Yeni Fatura Ekle", component: NewInvoiceForm, centered: false, size: "invoice", item: { copyItem: { ...item }, callback: this.getData } });
    },
    showInvoiceDetails(item) {
      this.$showAppModal({ item: { data: { ...item } }, title: "Fatura Detayları", centered: false, size: "xl", component: InvoiceDetails });
    },
    showContactDetails(item) {
      this.$router.push({ name: "finance-contact-detail", params: { id: item?.contact?.id } });
    },
    showSendInvoiceToEmailForm(item) {
      this.$showAppSidebar("E-Faturayı E-Postaya Gönder", SendInvoiceToEmailForm, { data: item });
    },
    getDescription(item) {
      return `${item?.description} ${item?.invoice_no ? " - " + item?.invoice_no : ""}`;
    },
    showNewInvoiceForm() {
      this.$showAppModal({ title: "Yeni Fatura Ekle", component: NewInvoiceForm, centered: false, size: "invoice", item: { callback: this.getData } });
    },
  },
  mounted() {
    this.$store.commit("setPaymentCallback", this.getData);
  },
  destroyed() {
    this.$store.commit("setPaymentCallback", null);
  },
};
</script>

<style></style>

<template>
  <div v-if="company">
    <new-email-modal ref="newEmailModal" />
    <app-select-input
      v-model="sendMailTo"
      @input="$emit('input', sendMailTo)"
      name="E-Posta Gönderilecekler"
      :options="options"
      label="E-Posta Gönderilecekler:"
      placeholder="E-Posta Gönderilecek Kişi Seçiniz..."
      select_label="email"
      :loading="loader"
      :reduce="(option) => option.email"
      multiple
    >
      <template #list-header>
        <div class="p-50 m-25">
          <app-button @click="showNewEmailModal" text="E-Posta Ekle" icon="PlusIcon" />
          <hr class="my-75" />
        </div>
      </template>
    </app-select-input>
  </div>
</template>

<script>
import NewEmailModal from "./NewEmailModal.vue";
export default {
  props: ["company"],
  components: { NewEmailModal },
  data() {
    return {
      sendMailTo: [],
      options: [],
      loader: false,
    };
  },
  methods: {
    showNewEmailModal() {
      this.company && this.$refs.newEmailModal.show({ company: this.company, callback: this.getData });
    },
    getData() {
      this.options = [];
      this.sendMailTo = [];
      this.$emit("input", []);
      this.loader = true;
      this?.company &&
        this.$axios
          .get("/finance/contact-emails/all", { params: { contact_id: this?.company?.contact_id } })
          .then((result) => {
            this.options = result.data;
            this.loader = false;
          })
          .catch((err) => {
            this.loader = false;
            console.log(err);
          });
    },
  },
  watch: {
    company() {
      this.getData();
    },
  },
  mounted() {
    this.getData();
  },
};
</script>

<style></style>

<template>
  <b-card class="mb-50" no-body>
    <b-row class="align-items-center p-1" :class="{ 'gap-15': windowWidth < 767 }">
      <b-col md="9">
        <h4 v-if="getValue('description')">
          {{ getValue("description") }}
        </h4>
        <div class="d-flex gap-15 align-items-center">
          <span> {{ getValue("invoice_no") }} </span>
          <b-badge v-if="getValue('category')">
            {{ getValue("category") }}
          </b-badge>
        </div>
      </b-col>
      <b-col md="3">
        <h5 class="m-0">
          {{ getValue("contact_name") }}
        </h5>
      </b-col>
    </b-row>
    <hr />
    <div v-if="getValue('invoice_note')">
      <div class="p-1 fs-13" v-html="getValue('invoice_note')"></div>
      <hr />
    </div>
    <div class="py-1">
      <b-table-lite :items="details" :fields="fields" responsive show-empty empty-text="Veri Bulunamadı" empty-filtered-text="Veri Bulunamadı">
        <template #cell(quantity)="{ item }">
          {{ getDetailValue(item, "quantity") }}
        </template>
        <template #cell(unit_price)="{ item }">
          {{ getDetailValue(item, "unit_price") }}
        </template>
        <template #cell(vat_rate)="{ item }">
          {{ getDetailValue(item, "vat_rate") }}
        </template>
        <template #cell(net_total)="{ item }">
          {{ getDetailValue(item, "net_total") }}
        </template>
      </b-table-lite>
    </div>
    <hr class="m-0" />
    <div class="p-1">
      <b-row>
        <b-col offset-lg="6" lg="6" xl="4" offset-xl="8">
          <b-list-group>
            <b-list-group-item class="d-flex justify-content-between align-items-center">
              <span class="font-weight-bolder">Ara Toplam</span>
              <span>{{ getValue("gross_total") }}</span>
            </b-list-group-item>

            <b-list-group-item class="d-flex justify-content-between align-items-center">
              <div class="d-flex flex-column gap-5">
                <span v-for="detailItem in detailVats" :key="detailItem.id"> Toplam KDV({{ getDetailValue(detailItem, "vat_rate") }}) </span>
                <span class="font-weight-bolder">Toplam KDV</span>
              </div>
              <div class="d-flex flex-column gap-5">
                <span v-for="detailItem in detailVats" :key="detailItem.id"> {{ getDetailValue(detailItem, "vat") }} </span>
                <span :class="{ 'font-weight-bolder': detailVats.length }">{{ getValue("total_vat") }}</span>
              </div>
            </b-list-group-item>

            <b-list-group-item class="d-flex justify-content-between align-items-center">
              <span class="font-weight-bolder">Genel Toplam</span>
              <span>{{ getValue("net_total") }}</span>
            </b-list-group-item>
          </b-list-group>
        </b-col>
      </b-row>
    </div>
  </b-card>
</template>

<script>
export default {
  props: {
    invoice: Object,
  },
  data() {
    return {
      fields: [
        { key: "product", label: "Hizmet/Ürün" },
        { key: "quantity", label: "Miktar" },
        { key: "unit_price", label: "BR. Fiyat" },
        { key: "vat_rate", label: "Vergi" },
        { key: "net_total", label: "Toplam" },
      ],
    };
  },
  computed: {
    item() {
      return this.invoice ?? this.$store.getters.appModalItem?.data;
    },
    details() {
      return this.invoice?.details ?? this.$store.getters?.appModalItem?.data?.details ?? [];
    },
    detailVats() {
      return this.details.length > 1 ? this.details : [];
    },
    windowWidth() {
      return this.$store.getters["app/windowWidth"];
    },
    getValue() {
      return (where) => {
        switch (where) {
          case "description":
            return this.item?.description;
          case "category":
            return this.item?.category;
          case "invoice_no":
            return this.item?.invoice_no;
          case "contact_name":
            return this.item?.contact?.name;
          case "gross_total":
            return this.$formatToTL(this?.item?.gross_total);
          case "total_vat":
            return this.$formatToTL(this?.item?.total_vat);
          case "net_total":
            return this.$formatToTL(this?.item?.net_total);
          case "invoice_note":
            return this.formattedText(this?.item?.invoice_note);
          default:
            return "";
        }
      };
    },
    getDetailValue() {
      return (item, where) => {
        switch (where) {
          case "quantity":
            return parseInt(item?.quantity)?.toFixed(0);
          case "unit_price":
            return this.$formatToTL(item?.unit_price);
          case "vat_rate":
            return parseInt(item?.vat_rate)?.toFixed(0) + " %";
          case "net_total":
            return this.$formatToTL(item?.net_total);
          case "vat":
            return this.$formatToTL(item?.vat);
          default:
            return "";
        }
      };
    },
  },
  methods: {
    formattedText(text) {
      return text
        ?.replace(/\n\n/g, "<br><br>")
        ?.replace(/\n/g, "<br>")
        ?.replace(/\*\*(.*?)\*\*/g, "<strong>$1</strong>")
        ?.replace(/\t/g, "&nbsp;&nbsp;&nbsp;&nbsp;");
    },
  },
};
</script>

<style></style>

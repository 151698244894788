<template>
  <app-overlay loading-variable="loadingOverlay">
    <db-searchable-table
      v-model="filters"
      @row-clicked="getContact"
      :items="items"
      :fields="fields"
      :total-rows="total"
      searchable
      @changed="getData"
      :hover="!selectedCompany && !selectedContact"
      :use-route-query="!selectedCompany && !selectedContact"
    >
      <template #modalbutton>
        <app-button @click="showPaymentForm" text="Tahsilat Ekle" icon="PlusIcon" size="md" />
      </template>
      <template #isFileAdded="{ item }">
        <b-badge :variant="item.isFileAdded === 'Var' ? 'success' : 'secondary'">
          {{ item.isFileAdded }}
        </b-badge>
      </template>
      <template #actions="{ item }">
        <app-dropdown>
          <b-dropdown-item @click="downloadFile(item)" v-if="item.isFileAdded === 'Var'">
            <feather-icon class="mr-25" icon="DownloadIcon" />
            İndir
          </b-dropdown-item>
          <b-dropdown-item @click="showUploadFileForm(item)" v-if="item.isFileAdded === 'Yok'">
            <feather-icon class="mr-25" icon="UploadIcon" />
            Belge Yükle
          </b-dropdown-item>
          <b-dropdown-item @click="deletePayment(item)">
            <feather-icon class="mr-25" icon="TrashIcon" />
            Sil
          </b-dropdown-item>
          <b-dropdown-item @click="downloadPaymentReceipt(item)">
            <feather-icon class="mr-25" icon="FileTextIcon" />
            Tahsilat Makbuzu
          </b-dropdown-item>
          <b-dropdown-item @click="showSendReceiptToEmailForm(item)">
            <feather-icon class="mr-25" icon="MailIcon" />
            Makbuzu Gönder
          </b-dropdown-item>
        </app-dropdown>
      </template>
    </db-searchable-table>
  </app-overlay>
</template>

<script>
import ContactPaymentForm from "../contacts/ContactPaymentForm.vue";
import UploadInvoicePaymentFileForm from "./UploadInvoicePaymentFileForm.vue";
import SendReceiptToEmailForm from "./SendReceiptToEmailForm.vue";
import { v4 as uuidv4 } from "uuid";

export default {
  data() {
    return {
      filters: {},
      items: [],
      total: 0,
    };
  },
  computed: {
    selectedContact() {
      return this.$store.getters?.selectedParasutContact;
    },
    selectedCompany() {
      return this.$store.getters.selectedCompany;
    },
    fields() {
      return [
        ...(!this.selectedCompany?.parasut_contact_id && !this.selectedContact?.contact_id
          ? [{ key: "companyName", label: "Müşteri Adı", limit: 20, sortable: true, tdClass: "no-wrap" }]
          : []),
        { key: "date", label: "Ödeme Tarihi", formatDate: true, sortable: true },
        { key: "createdAt", label: "İşlem Tarihi", formatDateTime: true, sortable: true },
        { key: "amount", label: "Tutar", formatToTL: true, sortable: true, tdClass: "no-wrap" },
        { key: "description", label: "Açıklama", limit: 20 },
        { key: "balance", label: "Bakiye", formatToTL: true, sortable: true, tdClass: "no-wrap" },
        { key: "isFileAdded", label: "Belge" },
        { key: "addedBy", label: "Kullanıcı" },
        { key: "actions", label: "İşlemler" },
      ];
    },
  },
  methods: {
    getData() {
      this.$axios
        .get("/finance/invoice-payments/list", {
          params: {
            ...this.filters,
            contact_id: this.selectedCompany?.parasut_contact_id ?? this.selectedContact?.contact_id,
            searchFields: JSON.stringify(["companyName"]),
          },
          loading: "table",
        })
        .then((result) => {
          this.items = result?.data?.data ?? [];
          this.total = result?.data?.total ?? 0;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getContact(item) {
      if (!this.selectedCompany && !this.selectedContact) this.$router.push({ name: "finance-contact-detail", params: { id: item?.contact_id } });
    },
    showPaymentForm() {
      const company = this.selectedCompany ? { ...this.selectedCompany } : null;
      const contact = this.selectedContact ? { ...this.selectedContact } : null;
      this.$showAppSidebar("Tahsilat Ekle", ContactPaymentForm, { data: company ?? contact, callback: this.getData });
    },
    showUploadFileForm(item) {
      this.$showAppSidebar("Belge Yükle", UploadInvoicePaymentFileForm, { data: { ...item }, callback: this.getData });
    },
    downloadFile(item) {
      item &&
        this.$axios
          .get(`/finance/invoice-payments/${item?._id}/file/download`, { loading: "table", responseType: "blob" })
          .then((result) => {
            const blob = new Blob([result.data], { type: result.headers["content-type"] });
            const link = document.createElement("a");
            link.href = window.URL.createObjectURL(blob);
            link.download = result.headers["content-disposition"]?.split("filename=")[1] ?? uuidv4();
            link.click();
          })
          .catch((err) => {
            console.log(err);
            this.$emitter.$emit("Notification", { title: "Hata Oluştu", variant: "danger" });
          });
    },
    showSendReceiptToEmailForm(item) {
      this.$showAppSidebar("Tahsilat Makbuzunu E-Posta'ya Gönder", SendReceiptToEmailForm, { data: { id: item?.transaction_id, contact_id: item?.contact_id } });
    },
    downloadPaymentReceipt(item) {
      this.$store.dispatch("downloadPaymentReceipt", { id: item?.transaction_id });
    },
    deletePayment(item) {
      this.$confirm({ message: "Sistemde kayıtlı dosya ve paraşüt üzerine işlenen ödeme silinecektir. Onaylıyor musunuz?" }, () => {
        this.$axios
          .delete(`/finance/delete-payment/${item._id}`)
          .then((result) => {
            this.getData();
            this.$emitter.$emit("Notification", { title: "İşlem Başarılı", variant: "success", message: result?.data?.message });
          })
          .catch((err) => {
            this.$emitter.$emit("Notification", { title: "Hata Oluştu", variant: "danger", message: err?.data?.message });
          });
      });
    },
  },
  mounted() {
    this.$store.commit("setPaymentCallback", this.getData);
  },
  destroyed() {
    this.$store.commit("setPaymentCallback", null);
  },
};
</script>

<style></style>

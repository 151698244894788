<template>
  <app-overlay loading-variable="sidebarOverlay">
    <validation-observer ref="contactEmailForm">
      <app-input v-model="email" name="E-Posta" label="E-Posta" placeholder="E-Posta Giriniz..." rules="required|email" />
      <app-button @click="updateItem ? updateData() : newData()" :text="updateItem ? 'Güncelle' : 'Ekle'" :icon="updateItem ? 'EditIcon' : 'PlusIcon'" size="md" block />
    </validation-observer>
  </app-overlay>
</template>

<script>
import { ValidationObserver } from "vee-validate";
export default {
  props: ["company"],
  components: { ValidationObserver },
  data() {
    return {
      email: "",
    };
  },
  computed: {
    selectedCompany() {
      return this.$store.getters?.selectedCompany ?? this.$store.getters?.selectedParasutContact ?? this.company;
    },
    callback() {
      return this?.$store.getters?.appSidebarItem?.callback;
    },
    updateItem() {
      return this?.$store.getters?.appSidebarItem?.updateItem;
    },
  },
  methods: {
    newData() {
      this.$validate(this.$refs.contactEmailForm, () => {
        this.$axios
          .post(
            "/finance/contact-emails",
            { email: this?.email, contact_id: this?.selectedCompany?.parasut_contact_id ?? this?.selectedCompany?.contact_id ?? this?.company?.contact_id },
            { loading: "sidebar" }
          )
          .then(() => {
            this.callback && this.callback();
            this.$emit("email-created");
            this.$store.commit("cleanAppSidebar");
          })
          .catch((err) => {
            this.$emitter.$emit("Notification", { variant: "danger", title: "Hata Oluştu", message: err?.data?.message });
          });
      });
    },
    updateData() {
      this.$validate(this.$refs.contactEmailForm, () => {
        this.$axios
          .patch(`/finance/contact-emails/${this?.updateItem?._id}`, { email: this?.email }, { loading: "sidebar" })
          .then(() => {
            this.callback && this.callback();
            this.$store.commit("cleanAppSidebar");
          })
          .catch((err) => {
            this.$emitter.$emit("Notification", { variant: "danger", title: "Hata Oluştu", message: err?.data?.message });
          });
      });
    },
  },
  mounted() {
    if (this?.updateItem) {
      this.email = this?.updateItem?.email;
    }
  },
};
</script>

<style></style>

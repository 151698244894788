var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',{staticClass:"mb-50",attrs:{"no-body":""}},[_c('b-row',{staticClass:"align-items-center p-1",class:{ 'gap-15': _vm.windowWidth < 767 }},[_c('b-col',{attrs:{"md":"9"}},[(_vm.getValue('description'))?_c('h4',[_vm._v(" "+_vm._s(_vm.getValue("description"))+" ")]):_vm._e(),_c('div',{staticClass:"d-flex gap-15 align-items-center"},[_c('span',[_vm._v(" "+_vm._s(_vm.getValue("invoice_no"))+" ")]),(_vm.getValue('category'))?_c('b-badge',[_vm._v(" "+_vm._s(_vm.getValue("category"))+" ")]):_vm._e()],1)]),_c('b-col',{attrs:{"md":"3"}},[_c('h5',{staticClass:"m-0"},[_vm._v(" "+_vm._s(_vm.getValue("contact_name"))+" ")])])],1),_c('hr'),(_vm.getValue('invoice_note'))?_c('div',[_c('div',{staticClass:"p-1 fs-13",domProps:{"innerHTML":_vm._s(_vm.getValue('invoice_note'))}}),_c('hr')]):_vm._e(),_c('div',{staticClass:"py-1"},[_c('b-table-lite',{attrs:{"items":_vm.details,"fields":_vm.fields,"responsive":"","show-empty":"","empty-text":"Veri Bulunamadı","empty-filtered-text":"Veri Bulunamadı"},scopedSlots:_vm._u([{key:"cell(quantity)",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getDetailValue(item, "quantity"))+" ")]}},{key:"cell(unit_price)",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getDetailValue(item, "unit_price"))+" ")]}},{key:"cell(vat_rate)",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getDetailValue(item, "vat_rate"))+" ")]}},{key:"cell(net_total)",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getDetailValue(item, "net_total"))+" ")]}}])})],1),_c('hr',{staticClass:"m-0"}),_c('div',{staticClass:"p-1"},[_c('b-row',[_c('b-col',{attrs:{"offset-lg":"6","lg":"6","xl":"4","offset-xl":"8"}},[_c('b-list-group',[_c('b-list-group-item',{staticClass:"d-flex justify-content-between align-items-center"},[_c('span',{staticClass:"font-weight-bolder"},[_vm._v("Ara Toplam")]),_c('span',[_vm._v(_vm._s(_vm.getValue("gross_total")))])]),_c('b-list-group-item',{staticClass:"d-flex justify-content-between align-items-center"},[_c('div',{staticClass:"d-flex flex-column gap-5"},[_vm._l((_vm.detailVats),function(detailItem){return _c('span',{key:detailItem.id},[_vm._v(" Toplam KDV("+_vm._s(_vm.getDetailValue(detailItem, "vat_rate"))+") ")])}),_c('span',{staticClass:"font-weight-bolder"},[_vm._v("Toplam KDV")])],2),_c('div',{staticClass:"d-flex flex-column gap-5"},[_vm._l((_vm.detailVats),function(detailItem){return _c('span',{key:detailItem.id},[_vm._v(" "+_vm._s(_vm.getDetailValue(detailItem, "vat"))+" ")])}),_c('span',{class:{ 'font-weight-bolder': _vm.detailVats.length }},[_vm._v(_vm._s(_vm.getValue("total_vat")))])],2)]),_c('b-list-group-item',{staticClass:"d-flex justify-content-between align-items-center"},[_c('span',{staticClass:"font-weight-bolder"},[_vm._v("Genel Toplam")]),_c('span',[_vm._v(_vm._s(_vm.getValue("net_total")))])])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <app-modal id="newEmailModal" title="Kişi Ekle" ref="newEmailModal" size="md"> <contact-email-form :company="company" @email-created="emailCreated" /> </app-modal>
</template>

<script>
import ContactEmailForm from "../contacts/ContactDetails/ContactEmails/ContactEmailForm.vue";
export default {
  data() {
    return {
      company: null,
      callback: null,
    };
  },
  components: { ContactEmailForm },
  methods: {
    show({ company, callback }) {
      this.company = company;
      this.callback = callback;
      this.$refs.newEmailModal.show();
    },
    emailCreated() {
      this.callback(this.company);
      this.$refs.newEmailModal.hide();
    },
  },
};
</script>

<style></style>

<template>
  <app-overlay loading-variable="sidebarOverlay">
    <validation-observer ref="invoicePaymentForm">
      <app-select-input v-if="!item" name="Firma" v-model="company" :options="options" label="* Firma:" select_label="name" placeholder="Firma Seçiniz..." rules="required" />
      <app-date-input v-model="date" name="Tarih" label="* Tarih:" placeholder="Tarih Seçiniz..." rules="required" />
      <app-select-input
        v-model="account_id"
        name="Hesap"
        :options="bankAccounts"
        select_label="name"
        label="* Hesap:"
        placeholder="Hesap Seçiniz..."
        rules="required"
        :reduce="(option) => option.id"
      />
      <app-input v-model.number="amount" name="Tutar" type="number" label="* Tutar" placeholder="Tutar Giriniz..." texticon="₺" rules="required" />
      <app-textarea-input v-model="description" name="Açıklama" label="Açıklama:" placeholder="Açıklama (İsteğe Bağlı)" />
      <send-sms-to-select v-model="sendSmsTo" :company="company || item" />
      <b-card body-class="p-1" class="border">
        <app-file-input v-model="file" label="Belge:" :accept="$acceptMimes(['pdf', 'jpg', 'png', 'jpeg'])" />
        <hr />
        <b-alert variant="secondary" show>
          <div class="alert-body">
            <small>
              - Sadece PDF, JPG ve PNG dosya formatı yüklenebilir. <br />
              - En fazla 5 MB boyutunda dosya yükleyebilirsiniz.
            </small>
          </div>
        </b-alert>
      </b-card>

      <app-button @click="createPayment" text="Tahsilat Ekle" size="md" block />
      <hr />
    </validation-observer>
  </app-overlay>
</template>

<script>
import { ValidationObserver } from "vee-validate";
import AddContactModal from "../calls/AddContactModal.vue";
import SendSmsToSelect from "./SendSmsToSelect.vue";
export default {
  components: { ValidationObserver, AddContactModal, SendSmsToSelect },
  data() {
    return {
      contactLoader: false,
      options: [],
      company: null,
      account_id: null,
      description: "",
      date: new Date(),
      amount: null,
      file: null,
      bankAccounts: [],
      contactOptions: [],
      sendSmsTo: [],
    };
  },
  computed: {
    item() {
      return this.$store.getters.appSidebarItem?.data;
    },
    callback() {
      return this.$store.getters.appSidebarItem?.callback;
    },
    paymentCallback() {
      return this.$store.getters?.paymentCallback;
    },
  },
  methods: {
    getBankAccounts() {
      this.$axios
        .get("/finance/bank-accounts")
        .then((result) => {
          this.bankAccounts = result.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    showAddContactModal() {
      (this.company || this?.item) && this.$refs.addContactModal.show({ company: this.company ?? this.item, callback: this.getContacts });
    },
    setData() {
      const formData = new FormData();
      formData.append("file", this.file);
      formData.append("account_id", this.account_id);
      formData.append("description", this.description);
      formData.append("date", this.date);
      formData.append("amount", this.amount);
      formData.append("companyTaxNo", this?.company?.taxNo ?? this?.item?.taxNo);
      formData.append("companyName", this.company?.name ?? this?.item?.name);
      formData.append("sendSmsTo", JSON.stringify(this.sendSmsTo));
      return formData;
    },
    createPayment() {
      this.$validate(this.$refs.invoicePaymentForm, () => {
        this.$axios
          .post(`/finance/contact-payment/${this?.company?.contact_id ?? this.item?.contact_id ?? this?.item?.parasut_contact_id}`, this.setData(), { loading: "sidebar" })
          .then((result) => {
            this.$route?.name === "finance-contact-detail" && this.$store.dispatch("getParasutContact", { id: this.$route.params?.id });
            this.callback && this.callback();
            !this.callback && this.paymentCallback && this.paymentCallback();
            this.$emitter.$emit("Notification", { title: "İşlem Başarılı", variant: "success", message: result.data.message ?? null });
            this.$store.commit("cleanAppSidebar");
          })
          .catch((err) => {
            this.$emitter.$emit("Notification", { title: "Hata Oluştu", variant: "danger", message: err.data.message ?? null });
          });
      });
    },
    getOptions() {
      this.$axios
        .get("/finance/parasut-companies/options", { loading: "sidebar" })
        .then((result) => {
          this.options = result.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
  mounted() {
    this.getBankAccounts();
    if (!this.item) this.getOptions();
  },
};
</script>

<style></style>

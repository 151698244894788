<template>
  <div v-if="company">
    <add-contact-modal ref="addContactModal" />
    <app-select-input
      v-model="sendSmsTo"
      @input="$emit('input', sendSmsTo)"
      name="SMS Gönderilecekler"
      :options="contactOptions"
      label="* SMS Gönderilecekler:"
      placeholder="SMS Gönderilecek Kişi Seçiniz..."
      select_label="fullname"
      rules="required"
      :loading="contactLoader"
      :reduce="(option) => option._id"
      multiple
    >
      <template #list-header>
        <div class="p-50 m-25">
          <app-button @click="showAddContactModal" text="Kişi Ekle" icon="PlusIcon" />
          <hr class="my-75" />
        </div>
      </template>
      <template #option="item">
        <div>{{ item.fullname }}</div>
        <div>({{ formatPhoneNumber(item.phone) }})</div>
      </template>
    </app-select-input>
  </div>
</template>

<script>
import AddContactModal from "../calls/AddContactModal.vue";
export default {
  components: { AddContactModal },
  props: ["company"],
  data() {
    return {
      sendSmsTo: [],
      contactOptions: [],
      contactLoader: false,
    };
  },
  methods: {
    showAddContactModal() {
      this.company && this.$refs.addContactModal.show({ company: this.company, callback: this.getContacts });
    },
    getContacts() {
      this.contactOptions = [];
      this.sendSmsTo = [];
      this.$emit("input", []);
      this.contactLoader = true;
      this?.company &&
        this.$axios
          .get("/company/contacts/all", { params: { parasut_contact_id: this?.company?.contact_id } })
          .then((result) => {
            this.contactOptions = result.data;
            this.contactLoader = false;
          })
          .catch((err) => {
            this.contactLoader = false;
            console.log(err);
          });
    },
    formatPhoneNumber(number) {
      let digits = number.replace(/\D/g, "");

      if (digits.length === 11) {
        return digits.replace(/(\d{4})(\d{3})(\d{2})(\d{2})/, "$1 $2 $3 $4");
      } else {
        return "Invalid phone number";
      }
    },
  },
  watch: {
    company() {
      this.getContacts();
    },
  },
  mounted() {
    this.getContacts();
  },
};
</script>

<style></style>

<template>
  <app-overlay loading-variable="sidebarOverlay">
    <validation-observer ref="invoicePaymentForm">
      <app-date-input v-model="date" name="Tarih" label="* Tarih:" placeholder="Tarih Seçiniz..." rules="required" />
      <app-select-input
        v-model="account_id"
        name="Hesap"
        :options="bankAccounts"
        select_label="name"
        label="* Hesap:"
        placeholder="Hesap Seçiniz..."
        rules="required"
        :reduce="(option) => option.id"
      />
      <app-input v-model.number="amount" name="Tutar" type="number" label="* Tutar" placeholder="Tutar Giriniz..." texticon="₺" rules="required" />
      <app-textarea-input v-model="description" name="Açıklama" label="Açıklama:" placeholder="Açıklama (İsteğe Bağlı)" />
      <b-card body-class="p-1" class="border">
        <app-file-input v-model="file" label="Belge:" :accept="$acceptMimes(['pdf', 'jpg', 'png', 'jpeg'])" />
        <hr />
        <b-alert variant="secondary" show>
          <div class="alert-body">
            <small>
              - Sadece PDF, JPG ve PNG dosya formatı yüklenebilir. <br />
              - En fazla 5 MB boyutunda dosya yükleyebilirsiniz.
            </small>
          </div>
        </b-alert>
      </b-card>

      <app-button @click="createPayment" text="Tahsilat Ekle" size="md" block />
      <hr />
    </validation-observer>
  </app-overlay>
</template>

<script>
import { ValidationObserver } from "vee-validate";
export default {
  components: { ValidationObserver },
  data() {
    return {
      account_id: null,
      description: "",
      date: new Date(),
      amount: null,
      file: null,
      bankAccounts: [],
    };
  },
  computed: {
    item() {
      return this.$store.getters?.appSidebarItem?.data;
    },
    callback() {
      return this.$store.getters?.appSidebarItem?.callback;
    },
  },
  methods: {
    getBankAccounts() {
      this.$axios
        .get("/finance/bank-accounts")
        .then((result) => {
          this.bankAccounts = result.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    setData() {
      const formData = new FormData();
      formData.append("file", this.file);
      formData.append("account_id", this.account_id);
      formData.append("description", this.description);
      formData.append("date", this.date);
      formData.append("amount", this.amount);
      formData.append("companyTaxNo", this?.item?.company?.attributes?.tax_number);
      formData.append("companyName", this?.item?.company?.attributes?.name);
      return formData;
    },
    createPayment() {
      this.$validate(this.$refs.invoicePaymentForm, () => {
        this.$axios
          .post(`/finance/invoice-payment/${this.item?.id}`, this.setData(), { loading: "sidebar" })
          .then((result) => {
            this.callback && this.callback();
            this.$emitter.$emit("Notification", { title: "İşlem Başarılı", variant: "success", message: result.data.message ?? null });
            this.$store.commit("cleanAppSidebar");
          })
          .catch((err) => {
            console.log(err);
          });
      });
    },
  },
  mounted() {
    this.getBankAccounts();
  },
};
</script>

<style></style>

<template>
  <app-overlay loading-variable="modalOverlay">
    <validation-observer ref="newInvoiceForm">
      <b-row>
        <b-col lg="6">
          <b-card class="border-secondary" body-class="p-1" title="Fatura Bilgileri">
            <app-input v-model="description" name="Fatura İsmi" label="Fatura İsmi:" placeholder="Fatura İsmi..." />
            <app-textarea-input v-model="invoice_note" rows="4" name="Fatura Açıklaması" label="Fatura Açıklaması:" placeholder="Fatura Açıklaması" />
            <app-date-input v-model="issue_date" name="Düzenlenme Tarihi" label="* Düzenlenme Tarihi:" placeholder="Düzenlenme Tarihi Seçiniz..." rules="required" />
            <div>
              <app-date-input v-model="due_date" name="Vade Tarihi" label="* Vade Tarihi:" placeholder="Vade Tarihi Seçiniz..." rules="required" />
              <b-button-group class="d-flex" size="sm" :vertical="windowWidth < 576">
                <b-button
                  @click="setDueDate(item.value)"
                  v-for="(item, key) in buttonOptions"
                  :key="key"
                  :variant="
                    calculateDaysBetweenTwoDate(issue_date, due_date) === 0 && item.value === 'now'
                      ? 'gradient-primary'
                      : calculateDaysBetweenTwoDate(issue_date, due_date) === item.value
                      ? 'gradient-primary'
                      : 'outline-secondary'
                  "
                  >{{ item.text }}</b-button
                >
              </b-button-group>
            </div>
            <hr />
            <b-form-radio-group
              v-model="invoice_type"
              class="d-flex justify-content-center align-items-center flex-wrap gap-5"
              :options="invoiceOptions"
              value-field="key"
              text-field="value"
            />
          </b-card>
        </b-col>
        <b-col lg="6">
          <b-card title="Diğer Seçimler" class="border-secondary" body-class="p-1">
            <app-select-input
              v-if="!item && !selectedCompany && !selectedContact"
              name="Firma"
              v-model="company"
              :options="options"
              label="* Firma:"
              select_label="name"
              placeholder="Firma Seçiniz..."
              rules="required"
              :loading="companyOptionsLoader"
            />
            <app-select-input
              name="Kategori"
              v-model="category"
              :options="categoryOptions"
              label="Kategori:"
              select_label="name"
              placeholder="Kategori Seçiniz..."
              :loading="categoryOptionsLoader"
            />
            <send-sms-to-select v-model="sendSmsTo" :company="company || selectedCompany || selectedContact || item" />
            <send-mail-to-select v-model="sendMailTo" :company="company || selectedCompany || selectedContact || item" />
            <app-input
              v-if="category && category.name === 'OSGB AYLIK HİZMET'"
              name="Çalışan Sayısı"
              type="number"
              label="Çalışan Sayısı:"
              placeholder="Çalışan Sayısı Giriniz..."
            />
          </b-card>
        </b-col>
        <!--  <b-col md="6">
          <send-sms-to-select v-model="sendSmsTo" :company="company || selectedCompany || selectedContact || item" />
        </b-col> -->
      </b-row>
      <hr class="mt-0" />
      <b-card class="border mb-1" body-class="p-0">
        <b-table :items="products" :fields="fields" show-empty empty-text="En az bir satır eklenmelidir." empty-filtered-text="En az bir satır eklenmelidir." responsive>
          <template #cell(product)="{ item }">
            <app-select-input
              @input="onProductSelected($event, item)"
              v-model="item.product"
              style="min-width: 325px"
              @search="(search, loading) => onProductSearch(search, loading, item)"
              name="Hizmet / Ürün"
              :options="item.productList"
              select_label="name"
              placeholder="Hizmet / Ürün Seçiniz..."
              searchable
              append-to-body
              no-gap
              rules="required"
            />
          </template>
          <template #cell(quantity)="{ item }">
            <app-input
              @input="calculateTotal(item)"
              style="min-width: 110px"
              name="Adet"
              v-model.number="item.quantity"
              type="number"
              right-text-icon="Adet"
              no-gap
              rules="required"
            />
          </template>
          <template #cell(unit_price)="{ item }">
            <app-input
              @input="calculateTotal(item)"
              style="min-width: 115px"
              name="Birim Fiyat"
              v-model.number="item.unit_price"
              type="number"
              right-text-icon="₺"
              no-gap
              rules="required"
            />
          </template>
          <template #cell(vat_rate)="{ item }">
            <app-select-input
              v-model.number="item.vat_rate"
              @input="calculateTotal(item)"
              style="min-width: 135px"
              name="Vergi"
              :options="taxOptions"
              select_label="label"
              placeholder="Vergi Seçiniz..."
              :reduce="(option) => option.value"
              append-to-body
              no-gap
              rules="required"
            />
          </template>
          <template #cell(net_total)="{ item }">
            <app-input @input="onTotalChanged(item)" v-model.number="item.net_total" style="min-width: 125px" name="Toplam Fiyat" type="number" right-text-icon="₺" no-gap />
          </template>
          <template #cell(actions)="{ index }">
            <app-dropdown>
              <b-dropdown-item @click="deleteLine(index)"> <feather-icon class="mr-25" icon="TrashIcon" /> Kaldır </b-dropdown-item>
            </app-dropdown>
          </template>
        </b-table>
        <hr />
        <app-button @click="addNewLine" class="ml-1" text="Yeni Satır Ekle" icon="PlusIcon" size="md" />
        <hr />
        <div class="p-1">
          <b-row>
            <b-col offset-lg="6" lg="6" xl="6" offset-xl="6">
              <b-list-group>
                <b-list-group-item class="d-flex justify-content-between align-items-center">
                  <span class="font-weight-bolder">Ara Toplam</span>
                  <span>{{ $formatToTL(calculateVatStatistics.grossTotal) }}</span>
                </b-list-group-item>

                <b-list-group-item class="d-flex justify-content-between align-items-center">
                  <div class="d-flex flex-column gap-5">
                    <template v-if="Object.keys(calculateVatStatistics.groupedVat).length > 1">
                      <span v-for="(value, key) in calculateVatStatistics.groupedVat" :key="key"> Toplam KDV(%{{ key }}) </span>
                    </template>
                    <span class="font-weight-bolder">Toplam KDV</span>
                  </div>
                  <div class="d-flex flex-column gap-5">
                    <template v-if="Object.keys(calculateVatStatistics.groupedVat).length > 1">
                      <span v-for="(value, key) in calculateVatStatistics.groupedVat" :key="key"> {{ $formatToTL(value) }} </span>
                    </template>
                    <span :class="{ 'font-weight-bolder': Object.keys(calculateVatStatistics.groupedVat).length > 1 }">
                      <span>{{ $formatToTL(calculateVatStatistics.totalVat) }}</span>
                    </span>
                  </div>
                </b-list-group-item>

                <b-list-group-item class="d-flex justify-content-between align-items-center">
                  <span class="font-weight-bolder">Genel Toplam</span>
                  <span>{{ $formatToTL(calculateVatStatistics.netTotal) }}</span>
                </b-list-group-item>
              </b-list-group>
            </b-col>
          </b-row>
          <hr />
        </div>
      </b-card>

      <app-button @click="newInvoice" text="Oluştur" icon="PlusIcon" size="md" />
      <hr />
    </validation-observer>
  </app-overlay>
</template>

<script>
import { ValidationObserver } from "vee-validate";
import SendSmsToSelect from "../contacts/SendSmsToSelect.vue";
import SendMailToSelect from "./SendMailToSelect.vue";
import { calculateDaysBetweenTwoDate } from "./invoiceUtils";

export default {
  components: { ValidationObserver, SendSmsToSelect, SendMailToSelect },
  data() {
    const singleProduct = { product: null, quantity: 1, unit_price: 0, vat: 0, vat_rate: 20, gross_total: 0, net_total: 0, productList: [] };
    return {
      invoice_type: "commercial",
      invoiceOptions: [
        { key: "basic", value: "Temel Fatura" },
        { key: "commercial", value: "Ticari Fatura" },
      ],
      taxOptions: [
        { value: 0, label: "%0" },
        { value: 1, label: "%1" },
        { value: 8, label: "%8" },
        { value: 10, label: "%10" },
        { value: 18, label: "%18" },
        { value: 20, label: "%20" },
      ],
      singleProduct,
      products: [],
      fields: [
        { key: "product", label: "Hizmet/Ürün" },
        { key: "quantity", label: "Miktar" },
        { key: "unit_price", label: "BR. Fiyat" },
        { key: "vat_rate", label: "Vergi" },
        { key: "net_total", label: "Toplam" },
        { key: "actions", label: "İşlemler" },
      ],
      companyOptionsLoader: false,
      categoryOptionsLoader: false,
      categoryOptions: [],
      productOptions: [],
      buttonOptions: [
        { text: "Aynı Gün", value: "now" },
        { text: "7 Gün", value: 7 },
        { text: "14 Gün", value: 14 },
        { text: "30 Gün", value: 30 },
        { text: "60 Gün", value: 60 },
      ],
      options: [],
      company: null,
      issue_date: new Date(),
      due_date: new Date(),
      description: "",
      invoice_note: null,
      category: null,
      sendSmsTo: [],
      sendMailTo: [],
      employeeCount: 0,
    };
  },
  computed: {
    item() {
      return this.$store.getters.appModalItem?.data;
    },
    copyItem() {
      return this.$store.getters?.appModalItem?.copyItem;
    },
    selectedContact() {
      return this.$store.getters?.selectedParasutContact;
    },
    selectedCompany() {
      return this.$store.getters.selectedCompany;
    },
    callback() {
      return this.$store.getters.appModalItem?.callback;
    },
    windowWidth() {
      return this.$store.getters["app/windowWidth"];
    },
    calculateVatStatistics() {
      const result = {
        groupedVat: {},
        totalVat: 0,
        grossTotal: 0,
        netTotal: 0,
      };

      this.products.forEach((product) => {
        const vat = parseFloat((product.unit_price * product.quantity * (product.vat_rate / 100)).toFixed(2));
        const grossTotal = parseFloat((product.unit_price * product.quantity).toFixed(2));
        const netTotal = parseFloat((grossTotal + vat).toFixed(2));

        if (!result.groupedVat[product.vat_rate]) {
          result.groupedVat[product.vat_rate] = 0;
        }
        result.groupedVat[product.vat_rate] += vat;
        result.groupedVat[product.vat_rate] = parseFloat(result.groupedVat[product.vat_rate].toFixed(2));

        result.totalVat += vat;
        result.grossTotal += grossTotal;
        result.netTotal += netTotal;

        result.totalVat = parseFloat(result.totalVat.toFixed(2));
        result.grossTotal = parseFloat(result.grossTotal.toFixed(2));
        result.netTotal = parseFloat(result.netTotal.toFixed(2));
      });

      return result;
    },
  },
  methods: {
    calculateDaysBetweenTwoDate,
    addDaysToDate(days) {
      const currentDate = this?.issue_date ? new Date(this?.issue_date) : new Date();
      currentDate.setDate(currentDate.getDate() + days);
      return currentDate;
    },
    getOptions() {
      this.companyOptionsLoader = true;
      this.$axios
        .get("/finance/parasut-companies/options")
        .then((result) => {
          this.options = result.data;
          this.companyOptionsLoader = false;
        })
        .catch((err) => {
          this.companyOptionsLoader = false;
          console.log(err);
        });
    },
    getCategoryOptions() {
      this.categoryOptionsLoader = true;
      this.$axios
        .get("/finance/parasut-categories/list", { params: { category_type: "SalesInvoice" } })
        .then((result) => {
          this.categoryOptions = result.data;
          this.categoryOptionsLoader = false;
        })
        .catch((err) => {
          console.log(err);
          this.categoryOptionsLoader = false;
        });
    },
    onProductSearch(search, loading, item) {
      if (search) {
        loading(true);
        this.$axios
          .get("/finance/parasut-products/paginated-list", {
            params: { search, searchFields: JSON.stringify(["name"]), limit: 30 },
          })
          .then((result) => {
            loading(false);
            item.productList = result?.data?.data;
          })
          .catch((err) => {
            loading(false);
            console.log(err);
          });
      }
    },
    addNewLine() {
      this.products.push({ ...this.singleProduct });
    },
    deleteLine(index) {
      this.products.splice(index, 1);
    },
    setData() {
      return {
        description: this.description,
        invoice_note: this.invoice_note ?? undefined,
        issue_date: this.issue_date,
        due_date: this.due_date,
        products: this.products.map((item) => ({
          product: item?.product?.product_id,
          quantity: item?.quantity,
          unit_price: item?.unit_price,
          vat_rate: item?.vat_rate,
        })),
        contact_id:
          this?.item?.contact_id ?? this?.item?.parasut_contact_id ?? this?.company?.contact_id ?? this.selectedContact?.contact_id ?? this.selectedCompany?.parasut_contact_id,
        category: this.category?.category_id ?? undefined,
        taxNo: this?.item?.taxNo ?? this?.item?.taxNo ?? this?.company?.taxNo ?? this.selectedContact?.taxNo ?? this.selectedCompany?.taxNo,
        sendSmsTo: this?.sendSmsTo,
        sendMailTo: this?.sendMailTo,
        invoice_type: this?.invoice_type,
        employeeCount: this.employeeCount,
      };
    },
    newInvoice() {
      this.$validate(this.$refs.newInvoiceForm, () => {
        this.products.length &&
          this.$confirm({ message: "Fatura oluşturulup resmileştirilecektir işleme devam etmek istiyor musunuz?" }, () => {
            this.$axios
              .post("/finance/parasut-invoices/new-invoice", this.setData(), { loading: "modal" })
              .then((r) => {
                const message = r?.data?.message;
                this.$emitter.$emit("Notification", {
                  title: "İşlem Başarılı",
                  variant: message ? "warning" : "success",
                  message,
                });
                this.callback && this.callback();
                this.$store.commit("cleanAppModal");
              })
              .catch((err) => {
                this.$emitter.$emit("Notification", {
                  title: "Hata Oluştu",
                  variant: "danger",
                });
                console.log(err);
              });
          });
      });
    },
    setDueDate(val) {
      this.due_date = val === "now" ? new Date() : this.addDaysToDate(val);
    },
    calculateTotal(item) {
      item.net_total = (item.quantity * (item.unit_price * (1 + item?.vat_rate / 100))).toFixed(2);
      item.gross_total = (item.quantity * item.unit_price).toFixed(2);
      item.vat = (item.net_total - item.gross_total).toFixed(2);
    },
    onProductSelected(val, item) {
      if (val) {
        item.vat_rate = parseFloat(val?.vat_rate) ?? 20;
        item.unit_price = parseFloat(val?.list_price) ?? 0;
        this.calculateTotal(item);
      } else {
        item.unit_price = 0;
        item.net_total = 0;
        item.quantity = 1;
      }
    },
    onTotalChanged(item) {
      item.unit_price = parseFloat((item.net_total / (1 + item.vat_rate / 100) / item.quantity).toFixed(2));
      item.gross_total = (item.quantity * item.unit_price).toFixed(2);
      item.vat = (item.net_total - item.gross_total).toFixed(2);
    },
  },
  mounted() {
    this.getOptions();
    this.getCategoryOptions();
    if (this.copyItem) {
      const due_days = calculateDaysBetweenTwoDate(this?.copyItem?.issue_date, this?.copyItem?.due_date);
      this.due_date = this.addDaysToDate(due_days);
      this.description = this?.copyItem?.description;
      this.invoice_note = this?.copyItem?.invoice_note;
      this.company = {
        contact_id: this?.copyItem?.contact?.id,
        name: this?.copyItem?.contact?.name,
      };
      this.category = this?.copyItem?.category ? { category_id: this.copyItem?.category_id, name: this?.copyItem?.category } : null;
      this.copyItem?.details?.forEach((item, key) => {
        this.products.push({
          ...this.singleProduct,
          product: { product_id: item?.product_id, name: item?.product },
          quantity: parseFloat(item?.quantity),
          unit_price: parseFloat(item?.unit_price).toFixed(2),
          vat_rate: parseFloat(item?.vat_rate),
        });
        this.calculateTotal(this.products[key]);
      });
    } else this.products.push({ ...this.singleProduct });
  },
};
</script>

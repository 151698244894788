<template>
  <app-overlay loading-variable="sidebarOverlay">
    <validation-observer ref="CompanyContactForm">
      <app-input v-model="fullname" name="İsim" label="* İsim:" placeholder="İsim..." rules="required|max:100" />
      <app-input v-model="phone" type="phone" name="Telefon" label="* Telefon:" placeholder="Telefon..." rules="required|min:13" />
      <app-input v-model="duty" name="Görevi" label="Görevi:" placeholder="Görevi..." rules="max:100" />
    </validation-observer>
    <app-button @click="updateItem ? updateContact() : createContact()" :text="updateItem ? 'Güncelle' : 'Ekle'" :icon="updateItem ? 'EditIcon' : 'PlusIcon'" size="md" block />
    <hr />
  </app-overlay>
</template>

<script>
import { ValidationObserver } from "vee-validate";
export default {
  components: { ValidationObserver },
  props: ["company"],
  data() {
    return {
      fullname: "",
      phone: "",
      duty: "",
    };
  },
  computed: {
    selectedCompany() {
      return this.$store.getters?.selectedCompany ?? this.$store.getters?.selectedParasutContact ?? this.company;
    },
    updateItem() {
      return this.$store.getters?.appSidebarItem?.updateItem;
    },
    callback() {
      return this.$store.getters?.appSidebarItem?.callback;
    },
  },
  methods: {
    setData() {
      return {
        ...this.setUpdateData(),
        company: this.selectedCompany?._id,
        companyTaxNo: this?.selectedCompany?.taxNo,
        parasut_contact_id: this?.selectedCompany?.parasut_contact_id ?? this?.selectedCompany?.contact_id,
      };
    },
    setUpdateData() {
      return {
        fullname: this?.fullname,
        phone: this?.phone?.replaceAll(" ", ""),
        duty: this.duty,
      };
    },
    createContact() {
      this.$validate(this.$refs.CompanyContactForm, () => {
        this.$axios
          .post("/company/contacts", this.setData(), { loading: "sidebar" })
          .then(() => {
            this.$emit("contact-created");
            this.callback && this.callback();
            !this.company && this.$store.commit("cleanAppSidebar");
          })
          .catch((err) => {
            this.$emitter.$emit("Notification", { variant: "danger", title: "Hata Oluştu", message: err?.data?.message });
          });
      });
    },
    updateContact() {
      this.$validate(this.$refs.CompanyContactForm, () => {
        this.$axios
          .patch(`/company/contacts/${this?.updateItem?._id}`, this.setUpdateData(), { loading: "sidebar" })
          .then(() => {
            this.callback && this.callback();
            !this.company && this.$store.commit("cleanAppSidebar");
          })
          .catch((err) => {
            this.$emitter.$emit("Notification", { variant: "danger", title: "Hata Oluştu", message: err?.data?.message });
          });
      });
    },
  },
  mounted() {
    if (this?.updateItem) {
      ["fullname", "phone", "duty"].map((item) => {
        this[item] = this.updateItem[item] ?? "";
      });
    }
  },
};
</script>

<style></style>

<template>
  <app-overlay loading-variable="sidebarOverlay">
    <validation-observer ref="sendInvoiceToEmailForm">
      <contact-email-auto-suggest-input v-model="to" :contact_id="item.contact.id" />
      <app-input v-model="subject" name="Konu:" label="Konu" placeholder="Konu (İsteğe Bağlı)" />
      <app-textarea-input v-model="mailContent" name="Açıklama" label="Açıklama:" placeholder="Açıklama (İsteğe Bağlı)" />
      <app-button @click="send" text="Gönder" size="md" block />
      <hr />
    </validation-observer>
  </app-overlay>
</template>

<script>
import { ValidationObserver } from "vee-validate";
import ContactEmailAutoSuggestInput from "../InvoicePayments/ContactEmailAutoSuggestInput.vue";
export default {
  components: { ValidationObserver, ContactEmailAutoSuggestInput },
  data() {
    return {
      subject: "",
      to: "",
      mailContent: "",
    };
  },
  computed: {
    item() {
      return this.$store.getters?.appSidebarItem?.data;
    },
  },
  methods: {
    setData() {
      return {
        to: this.to,
        subject: this.subject,
        mailContent: this.mailContent,
        e_invoice: this?.item?.e_invoice,
        e_invoice_type: this?.item?.e_invoice_type,
        invoice_no: this?.item?.invoice_no,
        contact_id: this?.item?.contact?.id,
      };
    },
    send() {
      this.$validate(this.$refs.sendInvoiceToEmailForm, () => {
        this.$axios
          .post(`/finance/parasut-invoices/send-e-invoice-to-email`, this.setData(), { loading: "sidebar" })
          .then(() => {
            this.$emitter.$emit("Notification", { title: "İşlem Başarılı", variant: "success" });
            this.$store.commit("cleanAppSidebar");
          })
          .catch((err) => {
            console.log(err);
            this.$emitter.$emit("Notification", { title: "Hata Oluştu", variant: "danger", message: err?.data?.message });
          });
      });
    },
  },
};
</script>

<style></style>

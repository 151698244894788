<template>
  <app-modal id="addContactModal" title="Kişi Ekle" ref="addContactModal" size="md"> <company-contact-form :company="company" @contact-created="contactCreated" /> </app-modal>
</template>

<script>
import CompanyContactForm from "@/views/Companies/details/info/contacts/CompanyContactForm.vue";
export default {
  data() {
    return {
      company: null,
      callback: null,
    };
  },
  components: { CompanyContactForm },
  methods: {
    show({ company, callback }) {
      this.company = company;
      this.callback = callback;
      this.$refs.addContactModal.show();
    },
    contactCreated() {
      this.callback(this.company);
      this.$refs.addContactModal.hide();
    },
  },
};
</script>

<style></style>

<template>
  <div>
    <auto-suggest-input
      @input="$emit('input', $event)"
      :datasuggest="emails"
      v-model="to"
      name="Gönderilecek E-Posta"
      label="* Gönderilecek E-Posta:"
      placeholder="E-Posta Giriniz..."
      rules="required|email"
    />
  </div>
</template>

<script>
import autoSuggestInput from "@/views/app-components/input/autoSuggestInput.vue";
export default {
  props: ["contact_id"],
  components: { autoSuggestInput },
  data() {
    return {
      to: "",
      emails: [],
    };
  },
  computed: {
    selectedCompany() {
      return this.$store.getters.selectedCompany;
    },
    item() {
      return this.$store.getters.selectedParasutContact;
    },
  },
  methods: {
    getEmails() {
      (this.contact_id || this?.item?.contact_id || this?.selectedCompany?.parasut_contact_id) &&
        this.$axios
          .get("/finance/contact-emails/all", { params: { contact_id: this.contact_id ?? this?.item?.contact_id ?? this?.selectedCompany?.parasut_contact_id } })
          .then((result) => {
            this.emails = result?.data?.map((item) => item?.email);
          })
          .catch((err) => {
            console.log(err);
          });
    },
  },
  mounted() {
    this.getEmails();
  },
};
</script>

<style></style>

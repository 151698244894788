<template>
  <app-overlay loading-variable="sidebarOverlay">
    <validation-observer ref="uploadInvoicePaymentFileForm">
      <b-card body-class="p-1" class="border">
        <app-file-input v-model="file" label="Belge:" :accept="$acceptMimes(['pdf', 'jpg', 'png', 'jpeg'])" requires />
        <hr />
        <b-alert variant="secondary" show>
          <div class="alert-body">
            <small>
              - Sadece PDF, JPG ve PNG dosya formatı yüklenebilir. <br />
              - En fazla 5 MB boyutunda dosya yükleyebilirsiniz.
            </small>
          </div>
        </b-alert>
      </b-card>

      <app-button @click="uploadFile" text="Belge Yükle" size="md" block />
      <hr />
    </validation-observer>
  </app-overlay>
</template>

<script>
import { ValidationObserver } from "vee-validate";
export default {
  components: { ValidationObserver },
  data() {
    return {
      file: null,
    };
  },
  computed: {
    modalItem() {
      return this.$store.getters.appModalItem;
    },
    item() {
      return this.$store.getters[this.modalItem ? "appModalSidebarItem" : "appSidebarItem"]?.data;
    },
    callback() {
      return this.$store.getters[this.modalItem ? "appModalSidebarItem" : "appSidebarItem"]?.callback;
    },
  },
  methods: {
    setData() {
      const formData = new FormData();
      formData.append("file", this.file);
      return formData;
    },
    uploadFile() {
      this.$validate(this.$refs.uploadInvoicePaymentFileForm, () => {
        this.$axios
          .patch(`/finance/invoice-payments/${this.item?._id}/file/upload`, this.setData(), { loading: "sidebar" })
          .then((result) => {
            this.callback && this.callback();
            this.modalItem?.callback && this.modalItem?.callback();
            this.$emitter.$emit("Notification", { title: "İşlem Başarılı", variant: "success", message: result.data.message ?? null });
            this.$store.commit(this.modalItem ? "cleanAppModalSidebar" : "cleanAppSidebar");
          })
          .catch((err) => {
            this.$emitter.$emit("Notification", { title: "Hata Oluştu", variant: "danger", message: err.data.message ?? null });
          });
      });
    },
  },
};
</script>

<style></style>

export const calculateDaysBetweenTwoDate = (issue_date, due_date) => {
  const issueDate = new Date(issue_date);
  const dueDate = new Date(due_date);

  if (isNaN(dueDate.getTime()) || isNaN(issueDate.getTime())) {
    return new Error("Invalid date string provided.");
  }
  const differenceInMilliseconds = dueDate.getTime() - issueDate.getTime();
  const differenceInDays = Math.floor(differenceInMilliseconds / (1000 * 60 * 60 * 24));

  return differenceInDays;
};
